.App {
  font-family: sans-serif;
  text-align: center;
}
code {
  font-family: Consolas, "courier new";
  color: crimson;
  background-color: #f1f1f1;
  padding: 5px;
  font-size: 105%;
}

.cv {
  margin-top: 25px;
}
